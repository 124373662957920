import { PaymentDashboardMap } from "./paymentDashboardAction";

const initialState = {
  currentWorkTask: {},
  transactionHistory: {},
  isLoading: false,
  worktaskSkip: 0,
  worktaskLimit: 1,
  transactionSkip: 0,
  transactionLimit: 1,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PaymentDashboardMap.GET_CURRENT_WORKTASK_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case PaymentDashboardMap.GET_CURRENT_WORKTASK_SUCCESS: {
      return {
        ...state,
        currentWorkTask: action.payload,
        isLoading: false,
      };
    }
    case PaymentDashboardMap.GET_CURRENT_WORKTASK_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case PaymentDashboardMap.GET_TRANSACTION_HISTORY_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case PaymentDashboardMap.GET_TRANSACTION_HISTORY_SUCCESS: {
      return {
        ...state,
        transactionHistory: action.payload,
        isLoading: false,
      };
    }
    case PaymentDashboardMap.GET_TRANSACTION_HISTORY_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case PaymentDashboardMap.SET_WORKTASK_BATCH_NUMBER: {
      return {
        ...state,
        worktaskLimit: action.payload,
      };
    }
    case PaymentDashboardMap.SET_TRANSACTION_BATCH_NUMBER: {
      return {
        ...state,
        transactionLimit: action.payload,
      };
    }
    default:
      return { ...state };
  }
};
