import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { ListItems, ListItems3 } from "../../../components/PackagesList/ListItems";
import in2 from "../../../assets/images/in2.svg";
import in5 from "../../../assets/images/in5.svg";
import col2 from "../../../assets/images/col2.svg";
import col3 from "../../../assets/images/col3.svg";
import buttonShow from "../../../assets/images/buttonShow.svg"
import empltyFile from "../../../assets/images/empltyFile.svg"

const Collaborator = () => {
  const [showTab, setShowTab] = useState(0)
  const handleChangeTab = (e) => {
    setShowTab(e)
    if (showTab === 1 && e === 1 || showTab === 2 && e === 2 || showTab === 3 && e === 3 || showTab === 4 && e === 4) {
      setShowTab(0)
    }
  }
  return (
    <>
      <Container className="shadowBox radius-top-0 bg-white">
        <div className="re_dashboardBoxInitiator">
          <div className="text-center re_dashboardBoxInitiatorItem">
            <img src={in2} alt={in2} />
            <div className="p4 pt-3 pb-1">Open</div>
            <div className="h1">0</div>
          </div>
          <div className="text-center re_dashboardBoxInitiatorItem">
            <img src={col2} alt={col2} />
            <div className="p4 pt-3 pb-1">
              In Progress
            </div>
            <div className="h1">0</div>
          </div>
          <div className="text-center re_dashboardBoxInitiatorItem">
            <img src={col3} alt={col3} />
            <div className="p4 pt-3 pb-1">
              Submitted
            </div>
            <div className="h1">0</div>
          </div>
          <div className="text-center re_dashboardBoxInitiatorItem">
            <img src={in5} alt={in5} />
            <div className="p4 pt-3 pb-1">Expired</div>
            <div className="h1">0</div>
          </div>
        </div>
      </Container>
      <Container className="bg-white shadowBox mt-4">
        <div className="d-flex align-items-center justify-content-between">
          <div className="h3">Open packages <span className="color_gray">(0)</span></div>
          <button type="button" className={`btn btn-link ${showTab === 1 ? "rotate180" : ""}`} onClick={() => handleChangeTab(1)}>
            <img src={buttonShow} alt="Show" />
          </button>
        </div>
        {showTab === 1 && <>
          <div className=" pt-3">
            <div className="p3 color_gray text-center py-4 my-md-4 d-flex flex-column align-items-center">
              <img src={empltyFile} alt="empltyFile" className="mb-3" />
                You have no open packages.
              </div>
            {/* <ListItems3
              usd={true}
              title="Latest Package name here"
              link=""
              tag1="Cooperative"
              tag2="Granted"
              tag3="21 March, 2021"
              tag4="Intermediate"
              bonus="50000.00"
              ldo={true}
              mgp="10000"
              text="Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in
        laying out print, graphic or web designs. Lorem ipsum, or lipsum as it
        is sometimes known, is dummy text used in laying out print, graphic or
        web designs. The passage is attributed to an unknown typesetter in the
        15th century who is thought to have scrambled parts of Cicero's De
        Finibus Bonorum et Malorum for use in a type specimen book."
            /> */}
          </div>

          {/* <div className="d-flex justify-content-center">
            <button type="button" className="btn btn-outline-black px-4">
              Show More
            </button>
          </div> */}
        </>}
      </Container>
      <Container className="bg-white shadowBox mt-4">
        <div className="d-flex align-items-center justify-content-between">
          <div className="h3">In Progress <span className="color_gray">(0)</span></div>
          <button type="button" className={`btn btn-link ${showTab === 1 ? "rotate180" : ""}`} onClick={() => handleChangeTab(2)}>
            <img src={buttonShow} alt="Show" />
          </button>
        </div>
        {showTab === 2 && <>
          <div className="p3 color_gray text-center py-4 my-md-4 d-flex flex-column align-items-center">
            <img src={empltyFile} alt="empltyFile" className="mb-3" />
                You have no in progress packages.
              </div>
          {/* <div className=" pt-3">
            <ListItems3
              database={true}
              title="Latest Package name here"
              link=""
              tag1="Cooperative"
              tag2="Granted"
              tag3="21 March, 2021"
              tag4="Intermediate"
              bonus="50000.00"
              text="Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in
        laying out print, graphic or web designs. Lorem ipsum, or lipsum as it
        is sometimes known, is dummy text used in laying out print, graphic or
        web designs. The passage is attributed to an unknown typesetter in the
        15th century who is thought to have scrambled parts of Cicero's De
        Finibus Bonorum et Malorum for use in a type specimen book."
            />
            <ListItems3
              database={true}
              title="Latest Package name here"
              link=""
              tag1="Cooperative"
              tag2="Granted"
              tag3="21 March, 2021"
              tag4="Intermediate"
              bonus="50000.00"
              text="Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in
        laying out print, graphic or web designs. Lorem ipsum, or lipsum as it
        is sometimes known, is dummy text used in laying out print, graphic or
        web designs. The passage is attributed to an unknown typesetter in the
        15th century who is thought to have scrambled parts of Cicero's De
        Finibus Bonorum et Malorum for use in a type specimen book."
            />
          </div>
          <div className="d-flex justify-content-center">
            <button type="button" className="btn btn-outline-black px-4">
              Show More
            </button>
          </div> */}
        </>}
      </Container>
      <Container className="bg-white shadowBox mt-4">
        <div className="d-flex align-items-center justify-content-between">
          <div className="h3">Submitted <span className="color_gray">(0)</span></div>
          <button type="button" className={`btn btn-link ${showTab === 1 ? "rotate180" : ""}`} onClick={() => handleChangeTab(3)}>
            <img src={buttonShow} alt="Show" />
          </button>
        </div>
        {showTab === 3 && <>
          <div className="p3 color_gray text-center py-4 my-md-4 d-flex flex-column align-items-center">
            <img src={empltyFile} alt="empltyFile" className="mb-3" />
            You have no packages with work started.
          </div>
        </>}
      </Container>
      <Container className="bg-white shadowBox mt-4">
        <div className="d-flex align-items-center justify-content-between">
          <div className="h3">Expired <span className="color_gray">(0)</span></div>
          <button type="button" className={`btn btn-link ${showTab === 1 ? "rotate180" : ""}`} onClick={() => handleChangeTab(4)}>
            <img src={buttonShow} alt="Show" />
          </button>
        </div>
        {showTab === 4 && <>
          <div className="p3 color_gray text-center py-4 my-md-4 d-flex flex-column align-items-center">
            <img src={empltyFile} alt="empltyFile" className="mb-3" />
            You have no packages with work started.
          </div>
        </>}
      </Container>
    </>
  );
};
export default Collaborator;
