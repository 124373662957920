import React from "react";
import { Modal } from "react-bootstrap";
import Earning1 from "../../assets/images/Earning1.svg";
import Earning2 from "../../assets/images/Earning2.svg";
import Earning3 from "../../assets/images/Earning3.svg";
import Earning4 from "../../assets/images/Earning4.svg";

const EarningModal = (props) => {
  return (
    <>
      <Modal show={props.show} onHide={props.handleClose} size="lg" centered>
        <Modal.Header className="px-4 pb-0 border-0" closeButton>
          <div className="h2 py-2">Earning</div>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div className="d-flex pb-3 align-items-center">
            <img src={Earning1} alt="" className="mr-2" />
            <div className="p3 ">Project Earning : <span className="color_gray">$25,000.00</span></div>
          </div>
          <div className="d-flex pb-3 align-items-center">
            <img src={Earning2} alt="" className="mr-2" />
            <div className="p3 ">Bonus: <span className="color_gray">$2,000.00</span></div>
          </div>
          <div className="d-flex pb-3 align-items-center">
            <img src={Earning3} alt="" className="mr-2" />
            <div className="p3 ">
              Project Details: <span className="color_gray">Harmony code round 1</span>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <img src={Earning4} alt="" className="mr-2" />
            <div className="d-flex align-items-center align-items-md-center">
              <div className="p3 pr-2 text-nowrap">Project Link:</div>
              <a
                href="https://rebaked.com/projets/harmony-code-round-1"
                target="_blank"
                className="p3 color_blue text-break"
              >
                https://rebaked.com/projets/harmony-code-round-1
              </a>
            </div>
          </div>


        </Modal.Body>
      </Modal>
    </>
  );
};
export default EarningModal;
