import { enviornment } from "../../constants/constants";
// import { addPackageAsync } from '../../modules/createnewproject/createProjectRedux/createProject.api';

// import { createInvestmentOnChainAsync, getProjectByIdAsync, offerStatusChangeByInvestorAsync, statusChangeByProjectCreatorAsync } from "../../modules/product/productRedux/product.api";
import { launchSaleAsync } from "../web3Redux/web3.api";

function getInstance(web3) {
    return new Promise(async (resolve, reject) => {
        if (web3 && web3 != '') {
            try {
                let Instance = await new web3.eth.Contract(
                    enviornment.ERC20ABI,
                    enviornment.ERC20Address
                );
                
                if (Instance) {
                    resolve(Instance);
                } else {
                    reject({ error: "Issue with instance" });
                }
            } catch (error) {
                reject(error);
            }
        }
    });
};

function getBalance(ercInstance, walletAddress) {
    return new Promise(async (resolve, reject) => {
        try {
            return await ercInstance.methods
                .balanceOf(walletAddress)
                .call({ from: walletAddress }, (err, data) => {

                    if (err) {
                        reject({ error: err});
                    } else {
                        if (data > 0) {
                            resolve(parseFloat(data / enviornment.divideValue).toFixed(2));
                        } else {
                            resolve(data)
                        }
                    }

                });
        } catch (error) {
            reject(error);
        }
    });
};

function createProject(ercInstance, walletAddress, data) {
    return new Promise(async (resolve, reject) => {
        console.log('ercInstance', ercInstance.methods, walletAddress, data)
        try {
            console.log("dddddddd")
            return await ercInstance.methods
                .createProject(data.token, data.budget)
                .send({ from: walletAddress })
                .on("receipt", function (receipt) {
                    resolve(receipt);
                })
                .on("error", function (error, receipt) {
                    reject({ error: error });
                });
        } catch (error) {
            console.log("error11", error)
            reject(error);
        }
    });
}

function approveProject(ercInstance, walletAddress, projectId) {
    return new Promise(async (resolve, reject) => {
        try {
            return await ercInstance.methods
                .approveProject(projectId)
                .send({ from: walletAddress })
                .on("receipt", function (receipt) {
                    resolve(receipt);
                })
                .on("error", function (error, receipt) {
                    reject({ error: error });
                });
        } catch (error) {
            reject(error);
        }
    });
}

export const poolMethods = {
    getInstance,
    createProject,
    approveProject
}