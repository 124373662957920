import React, { useState, useEffect } from "react";
import { Container, Table } from "react-bootstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Link } from "react-router-dom";
import { PageTitle } from "../../components/PageTitle/PageTitle";

import Database from "./../../assets/images/Database.svg";
import withdraw from "./../../assets/images/withdraw.svg";
import LoadingImage from "./../../assets/images/Rolling-1s-200px.svg";
import save_money from "./../../assets/images/save-money.svg";
import { PaymentDashboardActions } from "./redux/paymentDashboardAction";
import {
  getCurrentWorkTaskAsync,
  getTransactionHistoryAsync,
} from "./redux/paymentDashboardApi";
import { dayDifferenceTimestamp } from "../../utils";
import moment from "moment";

const PaymentDashboardCollaborator = () => {
  const dispatch = useDispatch();
  const {
    currentWorkTask,
    isLoading,
    workTaskLimit,
    transactionLimit,
    transactionHistory,
  } = useSelector((state) => state.paymentDashboard, shallowEqual);
  useEffect(() => {
    dispatch(getCurrentWorkTaskAsync());
    dispatch(getTransactionHistoryAsync());
  }, []);

  const daysDifferenceEndDate = (endDate) => {
    return dayDifferenceTimestamp(endDate, Date.now());
  };

  const setBatchNumber = () => {
    dispatch(PaymentDashboardActions.setWorkTaskBatchNumber(workTaskLimit + 5));
  };
  const setTransactionHistoryBatchNumber = () => {
    dispatch(
      PaymentDashboardActions.setTransactionBatchNumber(transactionLimit + 5)
    );
  };
  return (
    <>
      <PageTitle title="Dashboard" />

      <div className="pt-80 pb-80 min-height-50vh">
        <Container className="shadowBox bg-white">
          <div className="re_borderBox row">
            <div className="re_borderBoxItem col">
              <div className="d-flex flex-column align-items-center justify-content-end h-100">
                <div className="p4 opacity50">Net Income</div>
                <img src={Database} alt="" width="28px" className="my-2" />
                <div className="h5">$14,000.00</div>
              </div>
            </div>
            <div className="re_borderBoxItem col">
              <div className="d-flex flex-column align-items-center justify-content-end h-100">
                <div className="p4 opacity50">Balance Withdrawn</div>
                <img src={withdraw} alt="" className="my-2" />
                <div className="h5">$10,000.00</div>
              </div>
            </div>
            <div className="re_borderBoxItem col">
              <div className="d-flex flex-column align-items-center justify-content-end h-100">
                <div className="p4 opacity50">Available for withdrawal</div>
                <img src={save_money} alt="" className="my-2" />
                <div className="h5">$1,100.00</div>
              </div>
            </div>
          </div>
        </Container>
        <Container className="shadowBox bg-white mt-4">
          <div className="re_Profiletabs ">
            <button type="button" className="active">
              Transactions
            </button>
          </div>
          {transactionHistory &&
          transactionHistory.records &&
          transactionHistory.records.length > 0 ? (
            <Table responsive className="re_table">
              <thead>
                <tr>
                  <th>DATE</th>
                  <th>PROJECT TITLE</th>
                  <th>WORKTASK TITILE</th>
                  <th>CURRENCY</th>
                  <th>AMMOUNT</th>
                </tr>
              </thead>
              <tbody>
                {transactionHistory.records.map((transaction) => (
                  <tr>
                    <td>
                      {moment(transaction.createdAt).format(" DD MMM YY")}
                    </td>
                    <td>{transaction.title}</td>
                    <td>
                      <div className="d-flex justify-content-start">
                        <div className="re_ellips max-width-290px">
                          {transaction.name}
                        </div>
                        <Link to={`/project-details/${transaction._id}`}>
                          (View Project)
                        </Link>
                      </div>
                    </td>
                    <td>
                      <div className="re_ellips max-width-120px">
                        Binance Smart Coin
                      </div>
                    </td>
                    <td>$2,01,000.00</td>
                  </tr>
                ))}
                {transactionHistory &&
                transactionHistory.recordsFiltered <
                  transactionHistory.recordsTotal ? (
                  <button
                    type="button"
                    className="btn btn-gray btn-sm"
                    onClick={setTransactionHistoryBatchNumber}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        Loading...
                        <img
                          src={LoadingImage}
                          alt="LoadingImage"
                          width="20px"
                          className="ml-2"
                        />
                      </>
                    ) : (
                      "Show More"
                    )}
                  </button>
                ) : null}
              </tbody>
            </Table>
          ) : (
            <div className="text-center p3 color_gray ">
              No transaction history found
            </div>
          )}
        </Container>
        <Container className="shadowBox bg-white mt-4">
          <div className="re_Profiletabs ">
            <button type="button" className="active">
              Current WorkTasks
            </button>
          </div>
          {currentWorkTask &&
          currentWorkTask.records &&
          currentWorkTask.records.length > 0 ? (
            <Table responsive className="re_table">
              <thead>
                <tr>
                  <th>DUE DATE</th>
                  <th>PROJECT TITLE</th>
                  <th>WORKTASK TITILE</th>
                  <th>ROLE</th>
                  <th>DAYS LEFT</th>
                </tr>
              </thead>
              <tbody>
                {currentWorkTask.records.map((record) => (
                  <tr>
                    <td>{moment(record.endDate).format(" DD MMM YY")}</td>
                    <td>{record.title}</td>
                    <td>
                      <div className="d-flex justify-content-start">
                        <div className="re_ellips max-width-290px">
                          {record.name}
                        </div>
                        <Link to={`/project-details/${record._id}`}>
                          (View Project)
                        </Link>
                      </div>
                    </td>
                    <td>JR. Graphic Designer</td>
                    <td>
                      {record.endDate > Date.now() &&
                        daysDifferenceEndDate(record.endDate)}{" "}
                      Days
                    </td>
                  </tr>
                ))}
                {currentWorkTask &&
                currentWorkTask.recordsFiltered <
                  currentWorkTask.recordsTotal ? (
                  <button
                    type="button"
                    className="btn btn-gray btn-sm"
                    onClick={setBatchNumber}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        Loading...
                        <img
                          src={LoadingImage}
                          alt="LoadingImage"
                          width="20px"
                          className="ml-2"
                        />
                      </>
                    ) : (
                      "Show More"
                    )}
                  </button>
                ) : null}
              </tbody>
            </Table>
          ) : (
            <div className="text-center p3 color_gray ">
              No worktask present
            </div>
          )}
        </Container>
      </div>
    </>
  );
};

export default PaymentDashboardCollaborator;
