export const dashboardMap = {
    GET_AWAITING_APPROVAL_START: "GET_AWAITING_APPROVAL_START",
    GET_AWAITING_APPROVAL_SUCCESS: "GET_AWAITING_APPROVAL_SUCCESS",
    GET_AWAITING_APPROVAL_ERROR: "GET_AWAITING_APPROVAL_ERROR",

    GET_ALL_PACKAGES_REQUEST_RECEIVED_START: "GET_ALL_PACKAGES_REQUEST_RECEIVED_START",
    GET_ALL_PACKAGES_REQUEST_RECEIVED_SUCCESS: "GET_ALL_PACKAGES_REQUEST_RECEIVED_SUCCESS",
    GET_ALL_PACKAGES_REQUEST_RECEIVED_ERROR: "GET_ALL_PACKAGES_REQUEST_RECEIVED_ERROR",

    GET_ALL_USER_REQUEST_RECEIVED_START: "GET_ALL_USER_REQUEST_RECEIVED_START",
    GET_ALL_USER_REQUEST_RECEIVED_SUCCESS: "GET_ALL_USER_REQUEST_RECEIVED_SUCCESS",
    GET_ALL_USER_REQUEST_RECEIVED_ERROR: "GET_ALL_USER_REQUEST_RECEIVED_ERROR",

    ACCEPT_REJECT_REQUEST_START: "ACCEPT_REJECT_REQUEST_START",
    ACCEPT_REJECT_REQUEST_SUCCESS: "ACCEPT_REJECT_REQUEST_SUCCESS",
    ACCEPT_REJECT_REQUEST_ERROR: "ACCEPT_REJECT_REQUEST_ERROR",

    SET_AWAITING_APPROVAL_BATCH_NUMBER: "SET_AWAITING_APPROVAL_BATCH_NUMBER",
    SET_PACKAGE_BATCH_NUMBER: "SET_PACKAGE_BATCH_NUMBER",

    SET_USER_REQUEST_BATCH_NUMBER: "SET_USER_REQUEST_BATCH_NUMBER"
};

export const dashboardActions = {
    getAwaitingApprovalStart: (data) => ({
        type: dashboardMap.GET_AWAITING_APPROVAL_START,
        payload: data,
    }),
    getAwaitingApprovalSuccess: (data) => ({
        type: dashboardMap.GET_AWAITING_APPROVAL_SUCCESS,
        payload: data,
    }),
    getAwaitingApprovalError: (data) => ({
        type: dashboardMap.GET_AWAITING_APPROVAL_ERROR,
        payload: data,
    }),

    getAllPackagesRequestReceivedStart: (data) => ({
        type: dashboardMap.GET_ALL_PACKAGES_REQUEST_RECEIVED_START,
        payload: data,
    }),
    getAllPackagesRequestReceivedSuccess: (data) => ({
        type: dashboardMap.GET_ALL_PACKAGES_REQUEST_RECEIVED_SUCCESS,
        payload: data,
    }),
    getAllPackagesRequestReceivedError: (data) => ({
        type: dashboardMap.GET_ALL_PACKAGES_REQUEST_RECEIVED_ERROR,
        payload: data,
    }),

    getAllUserRequestReceivedStart: (data) => ({
        type: dashboardMap.GET_ALL_USER_REQUEST_RECEIVED_START,
        payload: data,
    }),
    getAllUserRequestReceivedSuccess: (data) => ({
        type: dashboardMap.GET_ALL_USER_REQUEST_RECEIVED_SUCCESS,
        payload: data,
    }),
    getAllUserRequestReceivedError: (data) => ({
        type: dashboardMap.GET_ALL_USER_REQUEST_RECEIVED_ERROR,
        payload: data,
    }),

    acceptRejectRequestStart: (data) => ({
        type: dashboardMap.ACCEPT_REJECT_REQUEST_START,
        payload: data,
    }),
    acceptRejectRequestSuccess: (data) => ({
        type: dashboardMap.ACCEPT_REJECT_REQUEST_SUCCESS,
        payload: data,
    }),
    acceptRejectRequestError: (data) => ({
        type: dashboardMap.ACCEPT_REJECT_REQUEST_ERROR,
        payload: data,
    }),

    setAwaitingApprovalBatchNumber: (data) => ({
        type: dashboardMap.SET_AWAITING_APPROVAL_BATCH_NUMBER,
        payload: data,
    }),

    setPackageBatchNumber: (data) => ({
        type: dashboardMap.SET_PACKAGE_BATCH_NUMBER,
        payload: data,
    }),

    setUserRequestBatchNumber: (data) => ({
        type: dashboardMap.SET_USER_REQUEST_BATCH_NUMBER,
        payload: data,
    }),
}