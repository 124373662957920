export const PaymentDashboardMap = {
  GET_CURRENT_WORKTASK_START: "GET_CURRENT_WORKTASK_START",
  GET_CURRENT_WORKTASK_SUCCESS: "GET_CURRENT_WORKTASK_SUCCESS",
  GET_CURRENT_WORKTASK_ERROR: "GET_CURRENT_WORKTASK_ERROR",
  GET_TRANSACTION_HISTORY_START: "GET_TRANSACTION_HISTORY_START",
  GET_TRANSACTION_HISTORY_SUCCESS: "GET_TRANSACTION_HISTORY_SUCCESS",
  GET_TRANSACTION_HISTORY_ERROR: "GET_TRANSACTION_HISTORY_ERROR",
  SET_WORKTASK_BATCH_NUMBER: "SET_WORKTASK_BATCH_NUMBER",
  SET_TRANSACTION_BATCH_NUMBER: "SET_TRANSACTION_BATCH_NUMBER",
};

export const PaymentDashboardActions = {
  getCurrentWorkTaskStart: (data) => ({
    type: PaymentDashboardMap.GET_CURRENT_WORKTASK_START,
    payload: data,
  }),
  getCurrentWorkTaskSuccess: (data) => ({
    type: PaymentDashboardMap.GET_CURRENT_WORKTASK_SUCCESS,
    payload: data,
  }),

  getCurrentWorkTaskError: (data) => ({
    type: PaymentDashboardMap.GET_CURRENT_WORKTASK_ERROR,
    payload: data,
  }),

  getTransactionHistoryStart: (data) => ({
    type: PaymentDashboardMap.GET_TRANSACTION_HISTORY_START,
    payload: data,
  }),
  getTransactionHistorySuccess: (data) => ({
    type: PaymentDashboardMap.GET_TRANSACTION_HISTORY_SUCCESS,
    payload: data,
  }),

  getTransactionHistoryError: (data) => ({
    type: PaymentDashboardMap.GET_TRANSACTION_HISTORY_ERROR,
    payload: data,
  }),
  setWorkTaskBatchNumber: (data) => ({
    type: PaymentDashboardMap.SET_WORKTASK_BATCH_NUMBER,
    payload: data,
  }),
  setTransactionBatchNumber: (data) => ({
    type: PaymentDashboardMap.SET_TRANSACTION_BATCH_NUMBER,
    payload: data,
  }),
};
