import React, { useState } from "react";
import picon from "../../../assets/images/p-icon.png";
import success from "../../../assets/images/success.svg";
import Database from "../../../assets/images/Database.svg";
import EarningModal from "../../../components/Modal/EarningModal";
import { Container } from "react-bootstrap";

const Earnings = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Container className="bg-white shadowBox radius-top-0">
        {/* ----------- */}
        <div className="text-center my-5 color_gray h3">
          You will soon be able to track your earnings & allocations here.
        </div>
        {/* <div className="row mx-0 justify-content-between re_EarningsList">
          <div className="col-md-8 d-flex  align-items-center">
            <div className="re_picon">
              <img src={picon} alt="icon" className="mw-100 mh-100" />
            </div>
            <div className="pl-3">
              <div className="d-flex flex-md-row flex-column align-items-start align-items-lg-center pb-2">
                <div className="p4 pr-2">Harmony code template</div>
                <span className="p4 color_gray text-nowrap">5 min ago</span>
              </div>
              <div className="d-flex flex-md-row flex-column align-items-start align-items-md-center pb-2">
                <div className="h5 d-flex align-items-center pr-3">
                  <img src={Database} alt="Database" className="mr-2" />
                  <span>14,000.00</span>
                </div>
                <div className="h5 d-flex align-items-center">
                  <img src={success} alt="success" className="mr-2" />
                  <span>10</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex align-items-center justify-content-md-end">
            <button
              type="button"
              className="btn btn-blue"
              onClick={handleShow}
            >
              View Detail
            </button>
          </div>
        </div> */}
        {/* ----------- */}

        <EarningModal show={show} handleClose={handleClose} />
      </Container>
    </>
  );
};
export default Earnings;
