import React from "react";
import { Container, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PageTitle } from "../../components/PageTitle/PageTitle";

import bonus from "./../../assets/images/dashboard/bonus.svg";
import money from "./../../assets/images/dashboard/money.svg";
import moneybag from "./../../assets/images/dashboard/money-bag.svg";
import pay from "./../../assets/images/dashboard/pay.svg";
import ReturnCancellations from "./../../assets/images/dashboard/ReturnCancellations.svg";

const ExpensesDashBoardInitiator = () => {
  return (
    <>
      <PageTitle title="Dashboard" />
      <div className="pt-80 pb-80 min-height-50vh">
        <Container className="shadowBox bg-white">
          <div className="re_borderBox row">
            <div className="re_borderBoxItem col">
              <div className="d-flex flex-column align-items-center justify-content-end h-100">
                <div className="p4 opacity50">
                  Total Remaining
                  <br />
                  Budget
                </div>
                <img src={moneybag} alt="" width="28px" className="my-2" />
                <div className="h5">$14,000.00</div>
              </div>
            </div>
            <div className="re_borderBoxItem col">
              <div className="d-flex flex-column align-items-center justify-content-end h-100">
                <div className="p4 opacity50">
                  Total
                  <br />
                  Spent
                </div>
                <img src={money} alt="" className="my-2" />
                <div className="h5">$10,000.00</div>
              </div>
            </div>
            <div className="re_borderBoxItem col">
              <div className="d-flex flex-column align-items-center justify-content-end h-100">
                <div className="p4 opacity50">
                  Bonuses
                  <br />
                  Distributed
                </div>
                <img src={bonus} alt="" className="my-2" />
                <div className="h5">$1,100.00</div>
              </div>
            </div>
            <div className="re_borderBoxItem col">
              <div className="d-flex flex-column align-items-center justify-content-end h-100">
                <div className="p4 opacity50">
                  Guaranteed Payment
                  <br />
                  Distributed
                </div>
                <img src={pay} alt="" className="my-2" />
                <div className="h5">$1,100.00</div>
              </div>
            </div>
            <div className="re_borderBoxItem col">
              <div className="d-flex flex-column align-items-center justify-content-end h-100">
                <div className="p4 opacity50">
                  Return from
                  <br />
                  Cancellations
                </div>
                <img src={ReturnCancellations} alt="" className="my-2" />
                <div className="h5">$1,100.00</div>
              </div>
            </div>
          </div>
        </Container>
        <Container className="shadowBox bg-white mt-4">
          <div className="re_Profiletabs ">
            <button type="button" className="active">
            Current WorkTasks
            </button>
          </div>
          <Table responsive className="re_table">
            <thead>
              <tr>
                <th>DATE</th>
                <th>COLLABORATOR</th>
                <th>WORKTASK TITILE</th>
                <th>TRANSACTION TYPE</th>
                <th>CURRENCY</th>
                <th>AMMOUNT</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Aug 22, 21</td>
                <td>JH.Probas</td>
                <td>
                  <div className="d-flex justify-content-start">
                    <div className="re_ellips max-width-290px">
                      Integration IDRT FIAT Gateway Harmony One
                    </div>
                    <Link to="">(View Project)</Link>
                  </div>
                </td>
                <td>Bonus</td>
                <td>$ETH</td>
                <td>$2,01,000.00</td>
              </tr>
              <tr>
                <td>Aug 22, 21</td>
                <td>JH.Probas</td>
                <td>
                  <div className="d-flex justify-content-start">
                    <div className="re_ellips max-width-290px">
                      Integration IDRT FIAT Gateway Harmony One
                    </div>
                    <Link to="">(View Project)</Link>
                  </div>
                </td>
                <td>Bonus</td>
                <td>$ETH</td>
                <td>$2,01,000.00</td>
              </tr>
            </tbody>
          </Table>
        </Container>
        <Container className="shadowBox bg-white mt-4">
          <div className="re_Profiletabs ">
            <button type="button" className="active">
            Delivered WorkTasks
            </button>
          </div>
          <Table responsive className="re_table">
            <thead>
              <tr>
                <th>DATE</th>
                <th>COLLABORATOR</th>
                <th>WORKTASK TITILE</th>
                <th>TRANSACTION TYPE</th>
                <th>CURRENCY</th>
                <th>AMMOUNT</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Aug 22, 21</td>
                <td>JH.Probas</td>
                <td>
                  <div className="d-flex justify-content-start">
                    <div className="re_ellips max-width-290px">
                      Integration IDRT FIAT Gateway Harmony One
                    </div>
                    <Link to="">(View Project)</Link>
                  </div>
                </td>
                <td>Bonus</td>
                <td>$ETH</td>
                <td>$2,01,000.00</td>
              </tr>
              <tr>
                <td>Aug 22, 21</td>
                <td>JH.Probas</td>
                <td>
                  <div className="d-flex justify-content-start">
                    <div className="re_ellips max-width-290px">
                      Integration IDRT FIAT Gateway Harmony One
                    </div>
                    <Link to="">(View Project)</Link>
                  </div>
                </td>
                <td>Bonus</td>
                <td>$ETH</td>
                <td>$2,01,000.00</td>
              </tr>
            </tbody>
          </Table>
        </Container>
      </div>
    </>
  );
};

export default ExpensesDashBoardInitiator;
