import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { ListItems3 } from "../../../components/PackagesList/ListItems";
import { ListItemsProject } from "../../../components/PackagesList/ListItemsProject";
import { getAwaitingApprovalAsync } from "../redux/dashboardApi";
import { dashboardActions } from "../redux/dashboardAction";
import SplashScreen from "../../../components/SplashScreen/SplashScreen";
import LoadingImage from "../../../assets/images/Rolling-1s-200px.svg";
import in1 from "../../../assets/images/in1.svg";
import in2 from "../../../assets/images/in2.svg";
import in3 from "../../../assets/images/in3.svg";
import in4 from "../../../assets/images/in4.svg";
import in5 from "../../../assets/images/in5.svg";
import buttonShow from "../../../assets/images/buttonShow.svg";
import empltyFile from "../../../assets/images/empltyFile.svg";

const Initiator = () => {
  const dispatch = useDispatch();

  const {
    awaitingApprovalRequests,
    isLoading,
    refreshAwaitingList,
    awaitingListLimit,
  } = useSelector((state) => state.dashboardDetail);

  useEffect(() => {
    if (refreshAwaitingList === true) dispatch(getAwaitingApprovalAsync());
  }, [refreshAwaitingList]);

  //On first time page render
  useEffect(() => {
    dispatch(getAwaitingApprovalAsync());
  }, []);

  const setBatchNumber = () => {
    dispatch(
      dashboardActions.setAwaitingApprovalBatchNumber(awaitingListLimit + 2)
    );
  };
  const [showTab, setShowTab] = useState(0);
  const handleChangeTab = (e) => {
    setShowTab(e);
    if (
      (showTab === 1 && e === 1) ||
      (showTab === 2 && e === 2) ||
      (showTab === 3 && e === 3) ||
      (showTab === 4 && e === 4) ||
      (showTab === 5 && e === 5)
    ) {
      setShowTab(0);
    }
  };
  return (
    <>
      {isLoading && <SplashScreen />}
      <Container className="shadowBox radius-top-0 bg-white">
        <div className="re_dashboardBoxInitiator">
          <div className="text-center re_dashboardBoxInitiatorItem">
            <img src={in1} alt={in1} />
            <div className="p4 pt-3 pb-1">Awaiting</div>
            <div className="h1">
              {awaitingApprovalRequests.recordsTotal
                ? awaitingApprovalRequests.recordsTotal
                : 0}
            </div>
          </div>
          <div className="text-center re_dashboardBoxInitiatorItem">
            <img src={in2} alt={in2} />
            <div className="p4 pt-3 pb-1">Open</div>
            <div className="h1">0</div>
          </div>
          <div className="text-center re_dashboardBoxInitiatorItem">
            <img src={in3} alt={in3} />
            <div className="p4 pt-3 pb-1">Completed</div>
            <div className="h1">0</div>
          </div>
          <div className="text-center re_dashboardBoxInitiatorItem">
            <img src={in4} alt={in4} />
            <div className="p4 pt-3 pb-1">Under Approval</div>
            <div className="h1">0</div>
          </div>
          <div className="text-center re_dashboardBoxInitiatorItem">
            <img src={in5} alt={in5} />
            <div className="p4 pt-3 pb-1">Expired</div>
            <div className="h1">0</div>
          </div>
        </div>
      </Container>
      <Container className="bg-white shadowBox mt-4">
        <div className="d-flex align-items-center justify-content-between">
          <div className="h3">
            Open packages <span className="color_gray">(0)</span>
          </div>
          <button
            type="button"
            className={`btn btn-link ${showTab === 1 ? "rotate180" : ""}`}
            onClick={() => handleChangeTab(1)}
          >
            <img src={buttonShow} alt="Show" />
          </button>
        </div>
        {showTab === 1 && (
          <>
            <div className=" pt-3">
              <div className="p3 color_gray text-center py-4 my-md-4 d-flex flex-column align-items-center">
                <img src={empltyFile} alt="empltyFile" className="mb-3" />
                You have no open packages.
              </div>
              {/* <ListItems3
              usd={true}
              title="Latest Package name here"
              link=""
              tag1="Cooperative"
              tag2="Granted"
              tag3="21 March, 2021"
              tag4="Intermediate"
              bonus="50000.00"
              ldo={true}
              mgp="10000"
              text="Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in
        laying out print, graphic or web designs. Lorem ipsum, or lipsum as it
        is sometimes known, is dummy text used in laying out print, graphic or
        web designs. The passage is attributed to an unknown typesetter in the
        15th century who is thought to have scrambled parts of Cicero's De
        Finibus Bonorum et Malorum for use in a type specimen book."
            /> */}
            </div>

            {/* <div className="d-flex justify-content-center">
            <button type="button" className="btn btn-outline-black px-4">
              Show More
            </button>
          </div> */}
          </>
        )}
      </Container>
      <Container className="bg-white shadowBox mt-4">
        <div className="d-flex align-items-center justify-content-between">
          <div className="h3">
            Awaiting approval{" "}
            <span className="color_gray">
              (
              {awaitingApprovalRequests &&
                awaitingApprovalRequests.recordsTotal}
              )
            </span>
          </div>
          <button
            type="button"
            className={`btn btn-link ${showTab === 1 ? "rotate180" : ""}`}
            onClick={() => handleChangeTab(2)}
          >
            <img src={buttonShow} alt="Show" />
          </button>
        </div>
        {showTab === 2 && (
          <>
            <div className=" pt-3">
              {console.log(awaitingApprovalRequests, "projectDetails")}
              {awaitingApprovalRequests.records &&
                awaitingApprovalRequests.records.map(
                  (projectDetails, index) => {
                    return (
                      <ListItemsProject
                        viewDetail={true}
                        viewDetailLink={`/package-list/${projectDetails._id}`}
                        title={projectDetails.title}
                        linkText="Project link"
                        link={`/project-details/${projectDetails._id}`}
                        bonus={
                          projectDetails.totalBudget
                            ? projectDetails.totalBudget.toFixed(2)
                            : 0
                        }
                        tokenName={projectDetails.tokenName}
                        text={projectDetails.description}
                        key={projectDetails._id}
                      />
                    );
                  }
                )}
            </div>
            {awaitingApprovalRequests &&
            awaitingApprovalRequests.recordsTotal === 0 ? (
              <>
                <div className="p3 color_gray text-center py-4 my-md-4 d-flex flex-column align-items-center">
                  <img src={empltyFile} alt="empltyFile" className="mb-3" />
                  You have no pending requests yet.
                </div>
              </>
            ) : awaitingApprovalRequests &&
              awaitingApprovalRequests.recordsFiltered <
                awaitingApprovalRequests.recordsTotal ? (
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-outline-black px-4"
                  onClick={setBatchNumber}
                  disabled={refreshAwaitingList}
                >
                  {refreshAwaitingList ? (
                    <>
                      Loading...
                      <img
                        src={LoadingImage}
                        alt="LoadingImage"
                        width="20px"
                        className="ml-2"
                      />
                    </>
                  ) : (
                    "Show More"
                  )}
                </button>
              </div>
            ) : null}
          </>
        )}
      </Container>
      <Container className="bg-white shadowBox mt-4">
        <div className="d-flex align-items-center justify-content-between">
          <div className="h3">
            Completed <span className="color_gray">(0)</span>
          </div>
          <button
            type="button"
            className={`btn btn-link ${showTab === 1 ? "rotate180" : ""}`}
            onClick={() => handleChangeTab(3)}
          >
            <img src={buttonShow} alt="Show" />
          </button>
        </div>
        {showTab === 3 && (
          <>
            <div className="p3 color_gray text-center py-4 my-md-4 d-flex flex-column align-items-center">
              <img src={empltyFile} alt="empltyFile" className="mb-3" />
              You have no packages with work started.
            </div>
          </>
        )}
      </Container>
      <Container className="bg-white shadowBox mt-4">
        <div className="d-flex align-items-center justify-content-between">
          <div className="h3">
            Under approval for completion{" "}
            <span className="color_gray">(0)</span>
          </div>
          <button
            type="button"
            className={`btn btn-link ${showTab === 1 ? "rotate180" : ""}`}
            onClick={() => handleChangeTab(4)}
          >
            <img src={buttonShow} alt="Show" />
          </button>
        </div>
        {showTab === 4 && (
          <>
            <div className="p3 color_gray text-center py-4 my-md-4 d-flex flex-column align-items-center">
              <img src={empltyFile} alt="empltyFile" className="mb-3" />
              You have no packages with work started.
            </div>
          </>
        )}
      </Container>
      <Container className="bg-white shadowBox mt-4">
        <div className="d-flex align-items-center justify-content-between">
          <div className="h3">
            Expired <span className="color_gray">(0)</span>
          </div>
          <button
            type="button"
            className={`btn btn-link ${showTab === 1 ? "rotate180" : ""}`}
            onClick={() => handleChangeTab(5)}
          >
            <img src={buttonShow} alt="Show" />
          </button>
        </div>
        {showTab === 5 && (
          <>
            <div className="p3 color_gray text-center py-4 my-md-4 d-flex flex-column align-items-center">
              <img src={empltyFile} alt="empltyFile" className="mb-3" />
              You have no packages with work started.
            </div>
          </>
        )}
      </Container>
    </>
  );
};
export default Initiator;
