import axios from "axios";
import { dashboardActions } from "./dashboardAction";
import { toast } from "react-toastify"

const apiUrl = process.env.REACT_APP_API;
const userBaseURL = process.env.REACT_APP_USER_BASE_URL;

export const getAwaitingApprovalAsync = () => {
    return async (dispatch, getState) => {
        try {
            dispatch(dashboardActions.getAwaitingApprovalStart());
            let { _id } = getState().auth.user;
            let { awaitingListLimit } = getState().dashboardDetail;
            const { data } = await axios({
                method: "GET",
                url: `${apiUrl}${userBaseURL}/getAllProjectRequestReceived/${_id}?skip=0&limit=${awaitingListLimit}`,
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (data.responseCode === 200) {
                return dispatch(
                    dashboardActions.getAwaitingApprovalSuccess(data.responseData)
                );
            }
            dispatch(dashboardActions.getAwaitingApprovalError());
            return toast.error(data.responseMessage);
        } catch (error) {
            dispatch(dashboardActions.getAwaitingApprovalError());
            return toast.error(
                " Error while fetching requests. Please try again after sometime."
            );
        }
    };
};


export const getAllPackagesRequestReceived = (projectId) => {
    return async (dispatch, getState) => {
        try {
            dispatch(dashboardActions.getAllPackagesRequestReceivedStart());
            let { _id } = getState().auth.user;
            let { packageListLimit } = getState().dashboardDetail;
            const { data } = await axios({
                method: "GET",
                url: `${apiUrl}${userBaseURL}/getAllPackageRequestReceived/${_id}/${projectId}?skip=0&limit=${packageListLimit}`,
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (data.responseCode === 200) {
                return dispatch(
                    dashboardActions.getAllPackagesRequestReceivedSuccess(data.responseData)
                );
            }
            dispatch(dashboardActions.getAllPackagesRequestReceivedError());
            return toast.error(data.responseMessage);
        } catch (error) {
            dispatch(dashboardActions.getAllPackagesRequestReceivedError());
            return toast.error(
                " Error while fetching requests. Please try again after sometime."
            );
        }
    };
};


export const getAllUserRequestReceived = (projectId, packageId) => {
    return async (dispatch, getState) => {
        try {
            dispatch(dashboardActions.getAllUserRequestReceivedStart());
            let { _id } = getState().auth.user;
            let { requestListSkip, requestListLimit } = getState().dashboardDetail;
            const { data } = await axios({
                method: "GET",
                url: `${apiUrl}${userBaseURL}/getUserRequestReceived/${_id}/${projectId}/${packageId}?skip=${requestListSkip}&limit=${requestListLimit}`,
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (data.responseCode === 200) {
                return dispatch(
                    dashboardActions.getAllUserRequestReceivedSuccess(data.responseData)
                );
            }
            dispatch(dashboardActions.getAllUserRequestReceivedError());
            return toast.error(data.responseMessage);
        } catch (error) {
            dispatch(dashboardActions.getAllUserRequestReceivedError());
            return toast.error(
                " Error while fetching requests. Please try again after sometime."
            );
        }
    };
};

export const acceptRejectRequest = (projectId, packageId, userId, status) => {
    return async (dispatch, getState) => {
        try {
            dispatch(dashboardActions.acceptRejectRequestStart());
            let { _id } = getState().auth.user;
            const { data } = await axios({
                method: "GET",
                url: `${apiUrl}${userBaseURL}/updateJoinRequest/${_id}/${projectId}/${packageId}/${userId}/${status}`,
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (data.responseCode === 200) {
                dispatch(
                    dashboardActions.acceptRejectRequestSuccess(data.responseData)
                );
                return toast.success(data.responseMessage);
            }
            dispatch(dashboardActions.acceptRejectRequestError());
            return toast.error(data.responseMessage);
        } catch (error) {
            dispatch(dashboardActions.acceptRejectRequestError());
            return toast.error(
                " Error while fetching requests. Please try again after sometime."
            );
        }
    };
};

