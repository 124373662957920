import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Container,
  Row,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Modal1 from "../../components/Modal/Modal";
import RatingModal from "../../components/RatingModal/RatingModal";
import { format, formatDistance, formatRelative, subDays } from "date-fns";
import queryString from "query-string";
import {
  getPackageAsync,
  joinPackageAsync,
  workPackageStartAsync,
  workPackageSubmitAsync,
  withdrawPackageAsync,
  finishPackageAsync,
} from "./redux/packageApi";

import { PackageMap } from "./redux/packageAction";

import prologo from "../../assets/images/prologo.svg";
import info from "../../assets/images/info.svg";
import ModalYesNo from "../../components/Modal/ModalYesNo";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SplashScreen from "../../components/SplashScreen/SplashScreen";
import LoadingImage from "../../assets/images/Rolling-1s-200px.svg";
import { PackageActions } from "./redux/packageAction";
import rightmark from "../../assets/images/rightmark.svg";
import packagecompleted from "../../assets/images/packagecompleted.svg";
import { useHistory } from "react-router-dom";

const PackageDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    packageDetail,
    isLoading,
    packageJoined,
    requestLoading,
    joinPackage,
    workStartLoading,
    workSubmitLoading,
    refreshPackageDetail,
    withdrawPackageLoading,
    finishPackageLoading,
    finishpackageResponse,
  } = useSelector((state) => state.packageDetail, shallowEqual);

  const { projectDetail } = useSelector(
    (state) => state.createProject,
    shallowEqual
  );

  const { _id } = useSelector((state) => state.auth.user, shallowEqual);

  const queryParams = queryString.parse(window.location.search);

  //range slider
  const [state, setState] = useState({
    values: "",
    update: "",
  });
  const handleChangeBudget = (values) => {
    setState({ values });
  };
  const handleUpdateBudget = (values) => {
    setState({ values });
  };

  //Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleJoinRequest = () => {
    if (queryParams && queryParams.projectId && queryParams.packageId) {
      dispatch(joinPackageAsync(queryParams.projectId, queryParams.packageId));
    }
  };

  useEffect(() => {
    return () => {
      dispatch({ type: PackageMap.RESET_JOIN_PACKAGE_INFO });
    };
  }, []);

  const handleStartWork = () => {
    //SC OBJECT
    const startWorkObj = {
      projectId_: queryParams.projectId,
    };
    //SC OBJECT

    dispatch(
      workPackageStartAsync(queryParams.projectId, queryParams.packageId)
    );
  };

  const handleSubmitWork = () => {
    /* dispatch(
      workPackageSubmitAsync(
        queryParams.projectId,
        queryParams.packageId,
        packageDetail.workProgress[0]._id
      )
    ); */
    history.push(
      `/submit-deliverable?projectId=${queryParams.projectId}&packageId=${queryParams.packageId}`
    );
  };
  //Modal
  const [showFinishPackage, setShowFinishPackage] = useState(false);
  const handleCloseFinish = () => setShowFinishPackage(false);

  //Modal
  const [showRating, setShowRating] = useState(false);
  const handleCloseRating = () => setShowRating(false);
  const handleShowRating = () => setShowRating(true);

  //Modal
  const [showWithdraw, setShowWithdraw] = useState(false);
  const handleCloseWithdraw = () => setShowWithdraw(false);
  const handleShowWithdraw = () => setShowWithdraw(true);

  const handleWithdraw = () => {
    if (queryParams && queryParams.projectId && queryParams.packageId) {
      dispatch(
        withdrawPackageAsync(queryParams.projectId, queryParams.packageId)
      );
      setShowWithdraw(false);
    }
  };
  const handleFinishPackage = () => {
    if (queryParams && queryParams.projectId && queryParams.packageId) {
      //SC OBJECT
      const finishPackageObj = {
        projectId_: queryParams.projectId,
        packageId_: queryParams.packageId,
      };
      //SC OBJECT

      dispatch(
        finishPackageAsync(queryParams.projectId, queryParams.packageId)
      );
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //calling api to fetch package detail
  useEffect(() => {
    if (
      queryParams &&
      queryParams.projectId &&
      queryParams.packageId &&
      refreshPackageDetail
    ) {
      dispatch(getPackageAsync(queryParams.projectId, queryParams.packageId));
    }
  }, [refreshPackageDetail]);

  useEffect(() => {
    if (packageJoined === true) setShow(true);
    /* return () => {
      dispatch(PackageActions.resetJoinPackageFlag());
    }; */
  }, [packageJoined]);

  useEffect(() => {
    if (finishpackageResponse.responseCode === 200) {
      setShowFinishPackage(true);
    }
  }, [finishpackageResponse]);

  useEffect(() => {
    return () => {
      dispatch(PackageActions.resetJoinPackageFlag());
    };
  }, []);

  return (
    <>
      {isLoading && <SplashScreen />}
      <section className="re_titleMain">
        <Container>
          <Row className="align-items-end">
            <Col lg={8}>
              <div className="mb-0 d-flex align-items-center re_detailsHdr">
                <div className="re_picon re_lg mr-3">
                  <img
                    src={
                      projectDetail && projectDetail.logo
                        ? projectDetail.logo
                        : prologo
                    }
                    alt="icon"
                    className="mw-100 mh-100"
                  />
                </div>
                <div>
                  <div className="p3 text-white pb-3">
                    {packageDetail && packageDetail.title}
                  </div>
                  <div className="h1 text-white pb-3">
                    {packageDetail && packageDetail.name}
                    <img src={rightmark} alt="rightmark" className="ml-2" />
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="re_blueGradiant re_lbl mr-2">
                      {packageDetail && packageDetail.minimumCost}{" "}
                      {packageDetail.tokenName !== null
                        ? packageDetail.tokenName
                        : "USD"}
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip className="re_tooltip">
                            Minimum Guaranteed Payment
                          </Tooltip>
                        }
                      >
                        <img src={info} alt="info" className="ml-2" />
                      </OverlayTrigger>
                    </div>

                    <div className="re_greenGradiant re_lbl">
                      {packageDetail && packageDetail.bonus !== ""
                        ? packageDetail.bonus
                        : 0}{" "}
                      {packageDetail.tokenName !== null
                        ? packageDetail.tokenName
                        : "USD"}{" "}
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip className="re_tooltip">Bonus</Tooltip>
                        }
                      >
                        <img src={info} alt="info" className="ml-2" />
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              lg={4}
              className="d-flex align-items-end justify-content-md-end pt-3"
            >
              {packageDetail &&
              packageDetail.initiator == _id &&
              packageDetail.adminVerification == "ACCEPTED" ? (
                packageDetail.workStatus !== "COMPLETED" ? (
                  <Button
                    variant="blue"
                    onClick={handleFinishPackage}
                    disabled={finishPackageLoading}
                  >
                    {finishPackageLoading ? (
                      <>
                        Finishing...
                        <img
                          src={LoadingImage}
                          alt="LoadingImage"
                          width="20px"
                          className="ml-2"
                        />
                      </>
                    ) : (
                      "Finish Package"
                    )}
                  </Button>
                ) : (
                  <img src={packagecompleted} alt="packagecompleted" />
                )
              ) : null}
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pt-80 pb-80">
        <Container className="bg-white shadowBox">
          <div className="h2 pb-4">{packageDetail.workStatus}</div>
          <Row>
            <Col xl={12}>
              <Row>
                <Col lg={4} md={6} className="pb-2">
                  <Row>
                    <Col sm={6}>
                      <div className="color_green p4">Package Link</div>
                    </Col>
                    <Col sm={6}>
                      <div className="color_black opacity70 p4 text-break">
                        {packageDetail && packageDetail.link}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg={4} md={6} className="pb-2">
                  <Row>
                    <Col sm={6}>
                      <div className="color_green p4">Opened</div>
                    </Col>
                    <Col sm={6}>
                      <div className="color_black opacity70 p4">
                        {packageDetail &&
                          packageDetail.startDate &&
                          formatDistance(
                            subDays(new Date(packageDetail.startDate), 0),
                            new Date(),
                            { addSuffix: true }
                          )}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg={4} md={6} className="pb-2">
                  <Row>
                    <Col sm={6}>
                      <div className="color_green p4">Type of Task</div>
                    </Col>
                    <Col sm={6}>
                      <div className="color_black opacity70 p4">
                        {packageDetail && packageDetail.issueType}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg={4} md={6} className="pb-2">
                  <Row>
                    <Col sm={6}>
                      <div className="color_green p4">Project Type</div>
                    </Col>
                    <Col sm={6}>
                      <div className="color_black opacity70 p4">
                        {packageDetail && packageDetail.projectType}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg={4} md={6} className="pb-2">
                  <Row>
                    <Col sm={6}>
                      <div className="color_green p4">Time Commitment</div>
                    </Col>
                    <Col sm={6}>
                      <div className="color_black opacity70 p4">
                        {packageDetail &&
                          packageDetail.startDate &&
                          format(
                            new Date(packageDetail.startDate),
                            "dd  MMM yyyy"
                          )}{" "}
                        -{" "}
                        {packageDetail &&
                          packageDetail.endDate &&
                          format(
                            new Date(packageDetail.endDate),
                            "dd  MMM yyyy"
                          )}
                        {/* 21 Mar - 27 Apr, 2021 */}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg={4} md={6} className="pb-2">
                  <Row>
                    <Col sm={6}>
                      <div className="color_green p4">Experience Level</div>
                    </Col>
                    <Col sm={6}>
                      <div className="color_black opacity70 p4">
                        {packageDetail && packageDetail.expertiseLevel}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          {_id && packageDetail.initiator && (
            <div className="d-flex flex-wrap align-items-center py-4">
              {packageDetail.initiator && packageDetail.initiator !== _id ? (
                packageDetail.collaborators &&
                !packageDetail.collaborators.includes(_id) ? (
                  (packageDetail.requests &&
                    packageDetail.requests.includes(_id)) ||
                  (joinPackage.requests &&
                    joinPackage.requests.includes(_id)) ? (
                    <>
                      <Button
                        variant="blue"
                        className="mr-3 mt-2 mt-md-0"
                        disabled={true}
                      >
                        Already Requested
                      </Button>
                    </>
                  ) : packageDetail.workStatus !== "COMPLETED" &&
                    packageDetail.workStatus !== "EXPIRED" &&
                    packageDetail.isEligibleToSendRequestAgain ? (
                    <>
                      <Button
                        variant="blue"
                        className="mr-3 mt-2 mt-md-0"
                        onClick={handleJoinRequest}
                        disabled={requestLoading}
                      >
                        {requestLoading ? (
                          <>
                            Requesting...
                            <img
                              src={LoadingImage}
                              alt="LoadingImage"
                              width="20px"
                              className="ml-2"
                            />
                          </>
                        ) : (
                          "Request to join"
                        )}
                      </Button>
                    </>
                  ) : null
                ) : (
                  <>
                    {packageDetail && Date.now() >= packageDetail.startDate ? (
                      packageDetail.workStatus === "OPEN" ||
                      packageDetail.workStatus === "INPROGRESS" ? (
                        <Button
                          variant="blue"
                          className="mr-3 mt-2 mt-md-0"
                          onClick={
                            packageDetail.workStatus === "OPEN"
                              ? handleStartWork
                              : handleSubmitWork
                          }
                          disabled={
                            packageDetail.workStatus === "OPEN"
                              ? workStartLoading
                              : workSubmitLoading
                          }
                        >
                          {packageDetail.workStatus === "OPEN" ? (
                            workStartLoading ? (
                              <>
                                Starting...
                                <img
                                  src={LoadingImage}
                                  alt="LoadingImage"
                                  width="20px"
                                  className="ml-2"
                                />
                              </>
                            ) : (
                              "Start Work"
                            )
                          ) : workSubmitLoading ? (
                            <>
                              Submitting...
                              <img
                                src={LoadingImage}
                                alt="LoadingImage"
                                width="20px"
                                className="ml-2"
                              />
                            </>
                          ) : (
                            "Submit Work"
                          )}
                        </Button>
                      ) : null
                    ) : null}

                    <Button
                      variant="outline-black"
                      className="mr-3 mt-2 mt-md-0"
                      onClick={handleShowWithdraw}
                    >
                      Withdraw Package
                    </Button>
                    <div className="d-flex flex-wrap align-items-center py-4">
                      <Link
                        to={{
                          pathname: `/deliverables`,
                          search: `?projectId=${
                            queryParams && queryParams.projectId
                          }&packageId=${queryParams && queryParams.packageId}`,
                        }}
                        className="btn btn-blue mr-3"
                      >
                        Project Board
                      </Link>
                    </div>
                    {console.log(packageDetail, "details-")}
                    {packageDetail.workStatus === "DELIVERED" &&
                      !packageDetail.isSubmittedRatings && (
                        <button
                          type="button"
                          className="btn btn-link-md mt-2 mt-md-0"
                          onClick={handleShowRating}
                        >
                          Rate your Collaborator
                        </button>
                      )}
                  </>
                )
              ) : (
                <div className="d-flex flex-wrap align-items-center py-4">
                  <Link
                    to={{
                      pathname: `/deliverables`,
                      search: `?projectId=${
                        queryParams && queryParams.projectId
                      }&packageId=${queryParams && queryParams.packageId}`,
                    }}
                    className="btn btn-blue"
                  >
                    Project Board
                  </Link>
                </div>
              )}
            </div>
          )}
          <div className="h3 pb-3">Description</div>
          <div className="p3 opacity70 pb-4">
            {packageDetail && packageDetail.description}
          </div>
          <div className="h3 pb-3">Context</div>
          <div className="p3 opacity70 pb-4">
            {packageDetail && packageDetail.context}
          </div>
          <div className="h3 pb-3">Acceptance Criteria</div>
          <div className="p3 opacity70 pb-4">
            {packageDetail && packageDetail.acceptanceCriteria}
          </div>
          <div className="h3 pb-3">Reference</div>
          <div className="p3 opacity70 pb-4">
            {packageDetail && packageDetail.reference}
          </div>
        </Container>
      </section>
      <Modal1
        show={show}
        handleClose={handleClose}
        text="Your request to join package has been submitted."
        subtext="Wait for approval from Initiator"
      />
      <Modal1
        show={showFinishPackage}
        handleClose={handleCloseFinish}
        text="Your request to deliver your work has been recorded."
        subtext="The project initiator will review, and validate if the submission meets the Minimum Acceptance Criteria."
      />
      <RatingModal
        showRating={showRating}
        handleCloseRating={handleCloseRating}
        title="Rate Collaborator"
        handleChangeBudget={handleChangeBudget}
        handleUpdateBudget={handleUpdateBudget}
      />
      <ModalYesNo
        show={showWithdraw}
        handleClose={handleCloseWithdraw}
        handleOk={handleWithdraw}
        showLoader={withdrawPackageLoading}
        showLoaderText="Withdrawing..."
        text="Are you sure want to withdraw package ?"
        subText="Your Minimum Guaranteed Remuneration will be recalled."
      />
    </>
  );
};
export default PackageDetails;
