import React from "react";
import { Container } from "react-bootstrap";


const Invite = () => {
  return (
    <>
      <Container className="shadowBox radius-top-0 bg-white">
        <div className="re_EarningsList px-4">
          <div className="p1">john@gmail.com</div>
        </div>
        <div className="re_EarningsList px-4">
          <div className="p1">john@gmail.com</div>
        </div>
        <div className="re_EarningsList px-4">
          <div className="p1">john@gmail.com</div>
        </div>
        <div className="re_EarningsList px-4">
          <div className="p1">john@gmail.com</div>
        </div>
      </Container>
    </>
  );
};
export default Invite;
