import { dashboardMap } from "./dashboardAction";

const initialState = {
    awaitingApprovalRequests: [],
    packageRequestReceived: [],
    userRequestReceived: [],
    isLoading: false,
    isRequestAccepted: false,
    refreshAwaitingList: false,
    refreshPackageList: false,
    awaitingListLimit: 2,
    packageListLimit: 4,
    requestListSkip: 0,
    requestListLimit: 5,
    refreshRequestList: false
  };
  
  export const reducer = (state = initialState, action) => {
    switch (action.type) {
      case dashboardMap.GET_AWAITING_APPROVAL_START: {
        return {
          ...state,
          isLoading: true,
        };
      }
      case dashboardMap.GET_AWAITING_APPROVAL_SUCCESS: {
        return {
          ...state,
          awaitingApprovalRequests: action.payload,
          isLoading: false,
          refreshAwaitingList: false
        };
      }
      case dashboardMap.GET_AWAITING_APPROVAL_ERROR: {
        return {
          ...state,
          isLoading: false,
          refreshAwaitingList: false
        };
      }

      case dashboardMap.GET_ALL_PACKAGES_REQUEST_RECEIVED_START: {
        return {
          ...state,
          isLoading: true,
        };
      }
      case dashboardMap.GET_ALL_PACKAGES_REQUEST_RECEIVED_SUCCESS: {
        return {
          ...state,
          packageRequestReceived: action.payload,
          isLoading: false,
          refreshPackageList: false
        };
      }
      case dashboardMap.GET_ALL_PACKAGES_REQUEST_RECEIVED_ERROR: {
        return {
          ...state,
          isLoading: false,
          refreshPackageList: false
        };
      }


      case dashboardMap.GET_ALL_USER_REQUEST_RECEIVED_START: {
        return {
          ...state,
          userRequestReceived: [],
          isLoading: true,
          refreshRequestList: false
        };
      }
      case dashboardMap.GET_ALL_USER_REQUEST_RECEIVED_SUCCESS: {
        return {
          ...state,
          userRequestReceived: action.payload,
          isLoading: false,
        };
      }
      case dashboardMap.GET_ALL_USER_REQUEST_RECEIVED_ERROR: {
        return {
          ...state,
          isLoading: false,
        };
      }

      case dashboardMap.ACCEPT_REJECT_REQUEST_START: {
        return {
          ...state,
          //isLoading: true,
          isRequestAccepted: true
        };
      }
      case dashboardMap.ACCEPT_REJECT_REQUEST_SUCCESS: {
        return {
          ...state,
          //isLoading: false,
          isRequestAccepted: false
        };
      }
      case dashboardMap.ACCEPT_REJECT_REQUEST_ERROR: {
        return {
          ...state,
          //isLoading: false,
        };
      }

      case dashboardMap.SET_AWAITING_APPROVAL_BATCH_NUMBER: {
        return {
          ...state,
          //isLoading: true,
          awaitingListLimit: action.payload,
          refreshAwaitingList: true
        };
      }

      case dashboardMap.SET_PACKAGE_BATCH_NUMBER: {
        return {
          ...state,
          //isLoading: true,
          packageListLimit: action.payload,
          refreshPackageList: true
        };
      }

      case dashboardMap.SET_USER_REQUEST_BATCH_NUMBER: {
        return {
          ...state,
          //isLoading: true,
          requestListSkip: action.payload,
          refreshRequestList: true
        };
      }
  
      default:
        return { ...state };
    }
  };
  